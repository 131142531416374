import { Box, Container, Stack, useTheme } from '@mui/material';

import { forwardRef } from 'react';

import { mergeSx } from '~/utils/mui';

import HomeButton from '~/components/molecules/HomeButton';

import BlankLayoutProps from './types';

const BlankLayout = forwardRef<HTMLDivElement, BlankLayoutProps>(({ children, showLogo, sx }, ref) => {
    const theme = useTheme();

    return (
        <Stack
            ref={ref}
            sx={mergeSx(
                {
                    minHeight: '100vh',
                },
                sx,
            )}
        >
            {showLogo && (
                <Box sx={mergeSx({ mb: { xs: 4, md: 8, lg: 16 } })}>
                    <Container
                        component="header"
                        maxWidth="xxl"
                        sx={{
                            p: { xs: 4, sm: 8, md: 12 },
                        }}
                    >
                        <Stack sx={{ width: '100%', alignItems: 'flex-start' }}>
                            <HomeButton
                                variant="square"
                                logoSx={{
                                    height: 28,
                                    color:
                                        theme.palette.mode === 'light'
                                            ? theme.palette.secondary.main
                                            : theme.palette.common.white,
                                }}
                                sx={{ ml: { xs: 0, md: -3 } }}
                            />
                        </Stack>
                    </Container>
                </Box>
            )}
            {children}
        </Stack>
    );
});

BlankLayout.displayName = 'BlankLayout';

export default BlankLayout;
